import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section>
      <div className="container">
        <div className="row">
          <div className='col-md-6 m-auto text-center'>
            <h1>Puslapis nerastas :(</h1>
            <a href="/kontaktai" className="btn btn-primary">Kontaktai</a>
          </div>
        </div>
      </div>
    </section>

  </Layout>
)

export default NotFoundPage